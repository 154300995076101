
















import mixins from 'vue-typed-mixins'
import Section from '@/calendesk/sections/section/mixins/Section'

export default mixins(Section).extend({
  name: 'Header1'
})
